import { Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import cellFull from "../images/gif/cell-full.gif"
import step1 from "../images/step-1.png"
import step2 from "../images/step-2.png"
import step3 from "../images/step-3.png"
import step4 from "../images/step-4.png"
import workspace from "../images/workspace.png"

const Trial = () => {
  const [step, setStep] = useState(0)
  const bg =
    step === 0
      ? "#1bb978"
      : step === 1
      ? "#00bba8"
      : step === 2
      ? "#00bad3"
      : step === 3
      ? "#00b4f2"
      : "#1aaaff"
  return (
    <Layout>
      <header
        className="gradient w-full pt-8 md:pt-16 font-sans shadow"
        style={{ minHeight: "80vh" }}
      >
        <h1 className="text-center md:text-5xl">
          <span>Explore</span> & Make Sense of Your Data
        </h1>
        <p className="w-4/5 md:w-2/5 mx-auto text-center">
          Explot lets you gain <b>insights</b> through data. Quickly visualize
          your csv files and answer your data questions by exploring them in a
          safe enviroment.
        </p>
        <div>
          <img
            src={cellFull}
            className="shadow-custom m-0 p-0 mx-auto rounded"
          />
        </div>
        <div>
          <div className="w-full mx-auto mt-8 mb-2 flex justify-center">
            <Link
              to="/buy/"
              className="shadow-xl bg-dark-green px-4 py-2 align-middle border border-transparent text-base leading-6 font-bold rounded-md text-white hover:text-black hover:shadow-2xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
            >
              Try 14-Day Trial
            </Link>
          </div>
          <ul className="flex justify-center mx-0 text-sm">
            <li className="mx-2">✓ Free for 14 days</li>
            <li className="mx-2">✓ Cancel anytime</li>
            <li className="mx-2">✓ Money back guaranteed</li>
          </ul>
        </div>
      </header>
      <section>
        <div className="flex flex-col md:flex-row justify-center mx-2 mt-16 py-8">
          <ul className="divide-y-2 m-0 md:ml-4 h-full my-0 font-sans text-xs md:text-sm leading-snug">
            <h2 className="text-center font-sans md:text-4xl">
              How To Get Started
            </h2>
            <li
              className="py-4 px-4 m-0 md:rounded-l-lg cursor-pointer md:transform hover:translate-x-2"
              onClick={() => setStep(0)}
              style={{
                backgroundColor: step === 0 ? bg : "white",
                color: step === 0 ? "white" : "black",
              }}
            >
              <h3 className="m-0 text-sm md:text-2xl">Step 1</h3>
              <p>Upload a file that you want to explore and visualize.</p>
            </li>
            <li
              className="py-4 px-4 m-0 md:rounded-l-lg cursor-pointer md:transform hover:translate-x-2"
              onClick={() => setStep(1)}
              style={{
                backgroundColor: step === 1 ? bg : "white",
                color: step === 1 ? "white" : "black",
              }}
            >
              <h3 className="m-0 text-sm md:text-2xl">Step 2</h3>
              <p>View the data in table format and select a chart type.</p>
            </li>
            <li
              className="py-4 px-4 m-0 md:rounded-l-lg cursor-pointer md:transform hover:translate-x-2"
              onClick={() => setStep(2)}
              style={{
                backgroundColor: step === 2 ? bg : "white",
                color: step === 2 ? "white" : "black",
              }}
            >
              <h3 className="m-0 text-sm md:text-2xl">Step 3</h3>
              <p>
                Select the data you want to visualize and how you want to
                aggregate it.
              </p>
            </li>
            <li
              className="py-4 px-4 m-0 md:rounded-l-lg cursor-pointer md:transform hover:translate-x-2"
              onClick={() => setStep(3)}
              style={{
                backgroundColor: step === 3 ? bg : "white",
                color: step === 3 ? "white" : "black",
              }}
            >
              <h3 className="m-0 text-sm md:text-2xl">Step 4</h3>
              <p>View your chart.</p>
            </li>
            <li
              className="py-4 px-4 m-0 md:rounded-l-lg cursor-pointer md:transform hover:translate-x-2"
              onClick={() => setStep(4)}
              style={{
                backgroundColor: step === 4 ? bg : "white",
                color: step === 4 ? "white" : "black",
              }}
            >
              <h3 className="m-0 text-sm md:text-2xl">Bonus</h3>
              <p>Create multiple charts side by side.</p>
            </li>
          </ul>
          <div
            className={`md:w-2/3 md:p-10 transition duration-500 ease-in-out md:py-16 md:rounded-r-lg z-10`}
            style={{ backgroundColor: bg }}
          >
            {step === 0 ? (
              <img
                src={step1}
                className="shadow-custom m-0 p-0 mx-auto my-auto rounded"
              />
            ) : step === 1 ? (
              <img
                src={step2}
                className="shadow-custom m-0 p-0 mx-auto rounded"
              />
            ) : step === 2 ? (
              <img
                src={step3}
                className="shadow-custom m-0 p-0 mx-auto rounded"
              />
            ) : step === 3 ? (
              <img
                src={step4}
                className="shadow-custom m-0 p-0 mx-auto rounded"
              />
            ) : (
              <img
                src={workspace}
                className="shadow-custom m-0 p-0 mx-auto rounded"
              />
            )}
          </div>
        </div>
      </section>
      <section
        className="my-24 md:py-16"
        style={{ backgroundColor: "#edf6fe" }}
      >
        <h2 className="hidden text-center text-4xl">Benefits</h2>
        <ul className="flex flex-col  md:flex-row w-5/6 mx-auto my-auto font-sans md:py-16">
          <li className="mx-4 my-8 md:my-0">
            <h3 className="mb-1 text-sm">Spend time where it matters</h3>
            <p className="text-xs leading-snug">
              Instead of spending time wrestling out data pipelines, SQL queries
              or chart dimensions, focus on viewing the data.
            </p>
          </li>
          <li className="mx-4 my-8 md:my-0">
            <h3 className="mb-1 text-sm">Gather the data in one place</h3>
            <p className="text-xs leading-snug">
              Explot doesn't care where your file comes from as long as you can
              export or save them as csv. You can view all your files in one
              place and compare how they affect each other.
            </p>
          </li>
          <li className="mx-4 my-8 md:my-0">
            <h3 className="mb-1 text-sm">Simple interface</h3>
            <p className="text-xs leading-snug">
              Explot doesn't clutter your screen with fluff data. So you can
              focus on the data that is core to the goal.
            </p>
          </li>
          <li className="mx-4 my-8 md:my-0">
            <h3 className="mb-1 text-sm">Focus on data questions</h3>
            <p className="text-xs leading-snug">
              Explot doesn't save or override the files you upload any changes
              you make are in explot only. Letting you focus on experimenting
              and finding insights.
            </p>
          </li>
        </ul>
      </section>
      <section className="font-sans my-16">
        <h2 className="text-center">Start Your 14-Day Trial</h2>
        <p className="text-center">
          All you need to get started is one csv file and some questions you
          want answered.
        </p>
        <div className="w-full mx-auto mt-8 mb-2 flex justify-center">
          <Link
            to="/buy/"
            className="bg-dark-green px-4 py-2 align-middle border border-transparent text-base leading-6 font-bold rounded-md text-white hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
          >
            Get Started
          </Link>
        </div>
      </section>
      <footer className="h-16 w-full bg-black content-center">
        <p className="text-white pt-4 pl-4 content-center align-center font-sans">
          <svg
            className="h-3 w-3 spin inline mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="white"
          >
            <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM7.88 7.88l-3.54 7.78 7.78-3.54 3.54-7.78-7.78 3.54zM10 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </svg>
          For questions and inquiries e-mail: <code>contact@explot.io</code>
        </p>
      </footer>
    </Layout>
  )
}

export default Trial
